import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import Layout from "../containers/layout";
import React from "react";
import Seo from "../components/seo";
import { responsiveTitle1 } from "../components/typography.module.css";

const ArchivePage = (props) => {
  const { posts, settings} = props.pageContext;

  return (
    <Layout
    logo={settings.logo}
    header={settings.header}
      footer={settings.footer}
    >
      <Seo title="Archive" />
      <Container>
        <h1 className={responsiveTitle1}>Archive</h1>
        {posts && posts.length > 0 && (
          <BlogPostPreviewGrid
            posts={posts}
            title={"Archive blogs"}
          />
        )}
      </Container>
    </Layout>
  );
};

export default ArchivePage;

// extracted by mini-css-extract-plugin
export var title1 = "typography-module--title1--8f913";
export var title2 = "typography-module--title2--9ffee";
export var title3 = "typography-module--title3--c0886";
export var large = "typography-module--large--591f2";
export var base = "typography-module--base--16985";
export var small = "typography-module--small--2877f";
export var micro = "typography-module--micro--cb01b";
export var paragraph = "typography-module--paragraph--e56c7";
export var blockQuote = "typography-module--blockQuote--36f9d";
export var responsiveTitle1 = "typography-module--responsiveTitle1--d2dd2";
export var responsiveTitle2 = "typography-module--responsiveTitle2--2cd97";
export var responsiveTitle3 = "typography-module--responsiveTitle3--2bebc";
export var responsiveTitle4 = "typography-module--responsiveTitle4--45808";